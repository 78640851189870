<template>
<div>
  <section class="section">
    <div class="ym-toptitle-box">
      <div id="toptitle" class="ym-toptitle">
        <div class="level">
          <h2 class="title level-left">Logowanie</h2>
        </div>
      </div>
    </div>
    <div class="columns is-variable  is-multiline">
      <div class="column is-12">
        <ValidationObserver v-slot="{ handleSubmit, errors }">
          <form class="login" @submit.prevent="handleSubmit(onSubmit)">
            <div :class="errors['username'] && errors['username'].length > 0 ? 'columns is-variable is-multiline v-field v-error' : 'columns is-variable is-multiline'">
              <div class="column ym-column is-12">
                  <div class="ym-label">
                    <label for="username">E-mail</label>
                 </div>
              </div>
              <div class="column ym-column is-12">
                <div class="field">
                  <div class="control is-medium">
                    <ValidationProvider rules="required|email" name="username">
                      <input name="username" type="text" v-model="user.username" class="input is-medium" />
                    </ValidationProvider>         
                    <span v-if="errors['username'] && errors['username'].length > 0" class="help is-danger">{{ errors['username'][0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div :class="errors['password'] && errors['password'].length > 0 ? 'columns is-variable is-multiline v-field v-error' : 'columns is-variable is-multiline'">
              <div class="column ym-column is-12">
                <div class="ym-label">
                  <label for="password">Hasło</label>
                </div>
              </div>
              <div class="column ym-column is-12">
                <div class="field">
                  <div class="control is-medium has-icons-right is-clearfix">
                    <ValidationProvider rules="required" name="password">
                      <input id="password" type="password" name="password" v-model="user.password" class="input is-medium"  />
                      <span  class="icon is-right has-text-primary is-clickable">
                        <i @click="passwordReveal" class="mdi mdi-eye mdi-24px"></i>
                      </span> 
                    </ValidationProvider> 
                    <span v-if="errors['password'] && errors['password'].length > 0" class="help is-danger">{{ errors['password'][0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-variable is-6 is-multiline">
                <div class="column ym-column is-12"></div>
                <div class="column ym-column is-12 mab15">
                  <router-link link="a" to="/password-remind">&raquo;&nbsp;Przypomnij hasło</router-link>
                </div>
            </div>          
            <div class="columns is-variable is-6 is-multiline">
              <div class="column ym-column is-12">
                <div class="ym-label">
                  <label for=""></label>
                  <p></p>
                </div>
              </div>
              <div class="column ym-column is-12">
                <div class="field">
                  <div class="control is-medium">
                    <button class="button is-medium is-dark is-justify is-fullwidth"><span>Zaloguj</span><icon name="log-in"></icon></button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </section>  
</div>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import {mapActions} from 'vuex'

  export default {
    name: 'Login',
    components: {
      ValidationObserver,
      ValidationProvider
    },    
    data: function() {
      return {
        user: {
          grant_type: 'password',
          client_id: 'Agromix API Account',
          client_secret: '2ddc43eee6c29c04bc63518347e8beeb76ddf726',
          username: '',
          password: '',
          function_role: 'technical' 
        }
      }
    },
    methods: {
    ...mapActions({
      authRequest: 'auth/authRequest'
    }),       
    onSubmit() {
      this.authRequest(this.user)
        .then(() => {
          // this.$rollbar.info('Hello world.');
          this.$router.push('/service')
        })
        .catch(error => {
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    },
    passwordReveal: function(e) {
      let password = document.querySelector('#password')
      if (password.type === "password") {
        e.target.classList.replace('mdi-eye', 'mdi-eye-off')
        password.type = "text";
      } else {
        e.target.classList.replace('mdi-eye-off', 'mdi-eye')
        password.type = "password";
      }      
    }
  }    
}
</script>
